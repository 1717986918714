// const redis = process.env.REDISCLOUD ? new Redis(process.env.REDISCLOUD) : new Redis()

import { createClient } from 'redis'
let redis = null
export async function connectToRedis() {
  const redis_url = process.env.REDIS_URL || process.env.STACKHERO_REDIS_URL_CLEAR
  redis = redis_url ? createClient({ url: redis_url }) : createClient()

  redis.on('error', err => console.log('Redis Client Error', err))
  redis.on('disconnect', err => {
    console.log('Redis Client disconnect', err)
    redis = connectToRedis()
  })
  await redis.connect()
  console.log('Connected to redis', await getCachePerformance())
  return redis
}

export async function get(key) {
  const cachedData = await redis.get(key)

  if (!cachedData) {
    return null
  }

  const { value } = JSON.parse(cachedData)
  return value
}

export async function put(key, value, expiryDuration = 10 * 60) {
  const data = JSON.stringify({ value })
  await redis.setEx(key, expiryDuration, data)
}

export async function addToCache(key, id, dataFn, expiryDuration = 10 * 60) {
  const cacheKey = `${key}:${id}`
  let data = await get(cacheKey)
  if (!data) {
    data = await dataFn()
    if (data) await put(cacheKey, data)
  } else {
    await redis.expire(cacheKey, expiryDuration)
  }
  return data
}

export async function getCachePerformance() {
  try {
    const stats = await redis.info('stats')
    const lines = stats.split('\r\n')
    const statsObj = lines.reduce((acc, line) => {
      const parts = line.split(':')
      if (parts.length === 2) {
        acc[parts[0]] = parts[1]
      }
      return acc
    }, {})

    const hits = parseInt(statsObj.keyspace_hits, 10)
    const misses = parseInt(statsObj.keyspace_misses, 10)
    const hitRatio = (hits / (hits + misses)).toFixed(2)

    return {
      hits,
      misses,
      hitRatio,
      totalCommandsProcessed: statsObj.total_commands_processed,
      // Add more metrics as needed
    }
  } catch (error) {
    console.error('Error fetching cache performance: ', error)
    return null
  }
}
