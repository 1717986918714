import orderDetails from './order-confirmation'
import { ordersApiGqlCall } from './utils'
import { loadRestaurantByID } from './restaurant'
import { loadMenusByRestaurantID } from './menus'
import { loadChannelByIDAndPermalink } from './channel'
import { loadLoyaltyBalance, getLoyaltyBalanceUrl } from './loyalty'
import { themeFromChannelConfig } from './theme'
import { loadOrderByID as loadPaymentsOrderByID } from './payments'
import { applyOrderAdjustmentsHierarchy as applyOrderAdjustments } from './edit-order'
import { addToCache } from '../utils/lru-cache-redis'
import Bugsnag from '@bugsnag/browser'

export const loadOrderByID = order_uuid =>
  ordersApiGqlCall('confirmation', orderDetails, { order_uuid })

export async function loadOrderFromRequest(req, deps) {
  const orderUUID = req.body['order_uuid']
  if (orderUUID) {
    // console.info(`Loading order ${orderUUID}...`)
    // console.time(`[TIME][ORDER][${orderUUID}]`)
    req.templateParams.order = await addToCache('order', orderUUID, async () =>
      applyOrderAdjustments(await loadOrderByID(orderUUID))
    )
    // console.info(`...loaded order ${req.templateParams.order.uuid}`)

    const knownCtxs = ['PaymentsCtx', 'RestaurantCtx', 'MenusCtx', 'ChannelCtx', 'LoyaltyCtx']
    return await Promise.all(
      deps
        .filter(dep => knownCtxs.includes(dep))
        .map(async id => {
          switch (id) {
            case 'PaymentsCtx':
              req.templateParams.payments = await addToCache(
                'payments_order',
                orderUUID,
                async () => await loadPaymentsOrderByID(orderUUID),
                1 * 60
              )
              return req.templateParams.payments
            case 'RestaurantCtx':
              req.templateParams.restaurant = await addToCache(
                'restaurant',
                req.templateParams.order.carts.restaurantId,
                async () => await loadRestaurantByID(req.templateParams.order.carts.restaurantId)
              )
              return req.templateParams.restaurant
            case 'MenusCtx':
              req.templateParams.menus = await addToCache(
                'menu',
                req.templateParams.order.carts.restaurantId,
                async () =>
                  await loadMenusByRestaurantID(req.templateParams.order.carts.restaurantId)
              )
              return req.templateParams.menus
            case 'ChannelCtx':
              req.templateParams.channel = await addToCache(
                'channel',
                `${req.templateParams.order.carts.channelId}-${req.templateParams.order.carts.integrationId}`,
                async () =>
                  await loadChannelByIDAndPermalink(
                    req.templateParams.order.carts.channelId,
                    req.templateParams.order.carts.integrationId
                  )
              )
              req.templateParams.theme = themeFromChannelConfig(req.templateParams.channel)
              return req.templateParams.channel
            case 'LoyaltyCtx':
              const loyaltyUrl = getLoyaltyBalanceUrl(
                req.templateParams.order.carts.customer.uuid,
                req.templateParams.order.carts.restaurantId,
                req.templateParams.order.carts.channelId
              )
              req.templateParams.loyaltyBalance = await addToCache(
                'loyalty',
                loyaltyUrl,
                async () => await loadLoyaltyBalance(loyaltyUrl)
              )
              return req.templateParams.loyaltyBalance
          }
        })
    )
  }
}

export default (req, res, next) => {
  const orderUUID = req.body['order_uuid']
  if (orderUUID) {
    console.info(`Loading order ${orderUUID}...`)
    console.time(`[TIME][ORDER][${orderUUID}]`)
    loadOrderByID(orderUUID)
      .then(data => {
        console.info(`...loaded order ${data.uuid}`)
        req.templateParams.order = applyOrderAdjustments(data)
        return Promise.all([
          loadPaymentsOrderByID(orderUUID).catch(_res => Promise.resolve(null)),
          loadRestaurantByID(data.carts.restaurantId),
          loadMenusByRestaurantID(data.carts.restaurantId),
          loadChannelByIDAndPermalink(data.carts.channelId, data.carts.integrationId),
          loadLoyaltyBalance(
            getLoyaltyBalanceUrl(
              data.carts.customer.uuid,
              data.carts.restaurantId,
              data.carts.channelId
            )
          ),
        ])
      })
      .then(([payments, restaurant, menus, channel, loyaltyBalance]) => {
        console.info(`...auto loaded payments ${payments ? payments.status : 'n/a'}`)
        console.info(`...auto loaded restaurant ${restaurant.name}`)
        console.info(`...auto loaded menus ${menus.length}`)
        console.info(`...auto loaded channel ${channel.name}`)
        console.info(`...auto loaded loyalty balance`, loyaltyBalance)
        req.templateParams.payments = payments
        req.templateParams.restaurant = restaurant
        req.templateParams.menus = menus
        req.templateParams.channel = channel
        req.templateParams.theme = themeFromChannelConfig(channel)
        req.templateParams.loyaltyBalance = loyaltyBalance
        console.timeEnd(`[TIME][ORDER][${orderUUID}]`)
        next()
      })
      .catch(error => {
        console.timeEnd(`[TIME][ORDER][${orderUUID}]`)
        console.error('Load order error', error)
        next(JSON.stringify(error, null, 2))
      })
  } else {
    next()
  }
}
