const orderDetails = `order(id: $order_uuid) {
    id
    uuid
    favorite
    ivrCode
    state
    createdAt
    order_workflow {
      logs {
        from_node
        to_node
        time
        transition
        transition_type
        initiator
      }
    }
    adjustments {
      id
      user_id
      actions
      state
      subtotal
      delivery_charge
      discount
      service_charge
      tax
      tip
      total
      inserted_at
      user_email
      user_name
      comments
    }
    carts {
      channelId
      integrationId
      restaurantId
      integrationUrl
      customer {
        uuid
        name
        email
        phone
        marketing_opt_in
        remote_id
        user_id
      }
      discounts {
        applies_to
        badge
        id
        title
      }
      instructions
      items {
        id
        item_id
        menu
        menu_id
        category
        comments
        name
        alias
        quantity
        item_total
        markup_for_item_total
        price
        markup_for_price
        serving_qty
        serving_label
        parent_id
        modifiers {
          name
          options {
            id
            name
            alias
            quantity
            total
            markup_for_total
          }
        }
      }
      settings {
        service {
          id
          label
          address { full delivery_instructions }
        }
        time { id value }
        curbsideInfo
        restaurant_location_address { full }
        tender {
          id
          value {
            card_holder_name
            card_last_digits
            type
          }
          label
        }
      }
      extra_fields
      cartCharges {
        id
        name
        amount
        taxable
        parent_id
      }
    }
    payment_info {
      authorization
      date_time
    }
    totals {
      delivery
      discount
      service
      subtotal
      tax
      included_tax
      tip
      total
      total_markup
      hidden
    }
    loyalty_rewards {
      label
      points
      provider
    }
    zuppler_loyalty_info {
      current_tier
      points_earned
      points_spent
      reward_amount
    }
  }`

export default orderDetails
